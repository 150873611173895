import React, { useState } from "react";
import PropTypes from "prop-types";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { StyledAccordion } from "./styles";
import { HubIcon } from "../HubIcon";
import { Typography } from "@mui/material";

export const HubAccordian = ({
  label,
  labelProps = {},
  children,
  defaultOpen = false,
  ...rest
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <StyledAccordion
      isOpen={open}
      expanded={open}
      onChange={toggleDrawer}
      disableGutters={true}
      square={true}
      {...rest}
    >
      <AccordionSummary
        expandIcon={
          <HubIcon icon="ChevronDown" size={12} />
        }
      >
        {typeof label === "string" ? (
          <Typography variant="span" {...labelProps}>
            {label}
          </Typography>
        ) : (
          label
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

HubAccordian.propTypes = {
  key: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
};

export default HubAccordian;
