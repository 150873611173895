import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import {
  HubIcon,
  HubDialogContent,
  HubButton,
  HubHookFormWrapper,
} from "../../components";

const iconActions = {
  success: { icon: "CheckCircle", sx: { color: "hubColors.greenMain" } },
  warning: { icon: "AlertCircle", sx: { color: "hubColors.yellowMain" } },
  info: { icon: "Info", sx: { color: "hubColors.blueMain" } },
  error: { icon: "XCircle", sx: { color: "hubColors.redMain" } },
};

export const HubActionDialog = ({
  onClose,
  open,
  title,
  description,
  customIcon,
  submitProps = {},
  cancelProps = {},
  dialogProps = {},
  iconProps = {},
  actionProps = {},
  handleSubmit = () => {},
  submitLabel = "Submit",
  cancelLabel = "Cancel",
  action = "success",
  includeCancel = true,
  children = <></>,
}) => {
  const iconFinalProps = {
    ...iconActions[action],
    ...iconProps,
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      open={open}
      {...dialogProps}
    >
      <HubDialogContent>
        <HubHookFormWrapper onSubmit={handleSubmit}>
          <Stack gap="45px" alignItems="center" p="25px">
            {customIcon ? (
              customIcon
            ) : (
              <HubIcon size="50px" {...iconFinalProps} />
            )}

            {(title || description) && (
              <Stack gap="10px" alignItems="center" width="100%">
                {title && <Typography variant="h6">{title}</Typography>}
                {description && (
                  <Typography sx={{ textAlign: "center" }}>
                    {description}
                  </Typography>
                )}
              </Stack>
            )}

            {children}

            <Stack gap="10px" width="100%" {...actionProps} direction="row">
              {includeCancel && (
                <HubButton
                  variant="contained"
                  color="grey"
                  size="small"
                  onClick={onClose}
                  sx={{ width: "100%" }}
                  {...cancelProps}
                >
                  {cancelLabel}
                </HubButton>
              )}
              <HubButton
                variant="contained"
                type="submit"
                sx={{ width: "100%" }}
                size="small"
                {...submitProps}
              >
                {submitLabel}
              </HubButton>
            </Stack>
          </Stack>
        </HubHookFormWrapper>
      </HubDialogContent>
    </Dialog>
  );
};

HubActionDialog.propTypes = {
  dialogProps: PropTypes.object,
  submitProps: PropTypes.object,
  cancelProps: PropTypes.object,
  iconProps: PropTypes.object,
  actionProps: PropTypes.object,
  customIcon: PropTypes.object,
  includeCancel: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  action: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  submitLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  children: PropTypes.node,
};

export default HubActionDialog;
