import React, { useMemo } from "react";
import { MenuItem, Typography } from "@mui/material";

import { StyledHubAccordian } from "./styles";

const SelectMenuItems = ({
  RenderComponent,
  options = [],
  getOptionValue = () => {},
  onSelectChange = () => {},
}) => {
  const getProps = (opt) => ({
    key: getOptionValue(opt),
    value: getOptionValue(opt),
    onClick: () => onSelectChange(null, getOptionValue(opt)),
  });

  return (
    <>
      {options.map((opt) => (
        <MenuItem {...getProps(opt)}>
          {RenderComponent ? (
            <RenderComponent {...opt} />
          ) : (
            <Typography sx={{ fontSize: "1em" }}>{opt.label || opt}</Typography>
          )}
        </MenuItem>
      ))}
    </>
  );
};
const HubHookFormSelectGroups = ({
  options = [],
  getOptionValue = () => {},
  onSelectChange = () => {},
  RenderComponent,
  groupByKey,
  defaultOpenGroup,
}) => {
  const props = { getOptionValue, onSelectChange, RenderComponent };
  if (!groupByKey) return <SelectMenuItems options={options} {...props} />;

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const groupedOptions = useMemo(
    () =>
      options.reduce((acc, option) => {
        const groupValue = getNestedValue(option, groupByKey) || "Other";
        if (!acc[groupValue]) {
          acc[groupValue] = [];
        }
        acc[groupValue].push(option);
        return acc;
      }, {}),
    [options]
  );

  const groups = Object.entries(groupedOptions)?.sort(
    ([a], [b]) => (b === defaultOpenGroup) - (a === defaultOpenGroup)
  );

  return (
    <>
      {groups.map(([group, items]) => (
        <StyledHubAccordian
          label={group}
          labelProps={{ variant: "boldCaption" }}
          key={group}
          defaultOpen={defaultOpenGroup && defaultOpenGroup === group}
        >
          <SelectMenuItems options={items} {...props} />
        </StyledHubAccordian>
      ))}
    </>
  );
};

export default HubHookFormSelectGroups;
