import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { HubButton } from "../../components";

const base = "(100% - 110px)";

const calculateRepeat = (factor, count = 2) =>
  Array(count).fill(`minmax(100px, calc(${base} * ${factor}))`).join(" ");

const repeat1 = calculateRepeat(0.229385);
const repeat2 = calculateRepeat(0.061875);
const repeat3 = calculateRepeat(0.10438, 4);

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: `20px 30px 40px ${repeat1} ${repeat2} ${repeat3} 20px`,
  alignItems: "center",
  borderBottom: "1px solid",
  borderRight: "1px solid",
  borderLeft: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
  ":hover": {
    backgroundColor: theme.palette.hubColors.greyLightest,
  },
  ".MuiInputBase-root": {
    // borderRight: "1px solid",
    // borderColor: theme.palette.hubColors.greyLight,
  },
  ".MuiInputBase-root, input": {
    borderRadius: 0,
    backgroundColor: "transparent !important",
  },
  fieldset: {
    // border: 0,
    // borderRight: "0.5px solid",
    // borderColor: theme.palette.hubColors.greyLight,
    borderWidth: 0.5,
  },
}));

export const StyledPlaceholderDiv = (placeholderProps, theme) => {
  console.log(placeholderProps.clientHeight);
  return placeholderProps
    ? {
        position: "absolute",
        top: placeholderProps.clientY,
        left: placeholderProps.clientX,
        height: `calc(${placeholderProps.clientHeight}px - 5px)`,
        width: placeholderProps.clientWidth,
        boxSizing: "border-box",
        borderRadius: "10px",
        border: "1px dashed",
        borderColor: theme.palette.hubColors.mainFocus,
        backgroundColor: theme.palette.hubColors.mainFocusLightest,
        zIndex: 0,
      }
    : { display: "none" };
};

export const StyledHubButton = styled(HubButton)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));
