import React from "react";

import { StyledStack } from "./styles.js";
import HubCircularProgress from "../HubCircularProgress/HubCircularProgress.jsx";

export const PageContent = ({
  disablePadding,
  smallPadding,
  mediumPadding,
  isLoading,
  children,
  hideOverflow = false,
  skeleton,
  ...rest
}) => {
  let padding = "35px";
  if (disablePadding) padding = "0px";
  else if (smallPadding) padding = "15px";
  else if (mediumPadding) padding = "25px";

  const sx = {
    padding,
    ...(hideOverflow ? { overflow: "hidden" } : {}),
  };

  const loader = skeleton || <HubCircularProgress centerScreen />;
  return (
    <StyledStack {...sx} {...rest} className="hub-page-content">
      {isLoading ? loader : children}
    </StyledStack>
  );
};
