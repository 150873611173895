import { StyledSpan } from "./style";

export const getDescriptions = (stationLabel = "") => ({
  rework: (
    <StyledSpan>
      You are about to <strong>REVERT</strong> the current work order routing to{" "}
      {stationLabel}, this means a certain amount of parts needs to be reworked.
      Please enter the amount that successfully completed. (that do{" "}
      <strong>NOT</strong> need to be reworked)
    </StyledSpan>
  ),
  advance: (
    <StyledSpan>
      You are about to <strong>ADVANCE</strong> the current work order routing
      to {stationLabel}, this will complete the routings for any incomplete
      steps till the new routing, <strong>NOT</strong> including the new
      routing.
    </StyledSpan>
  ),
  default: (
    <StyledSpan>
      You are about to update the details of this work order, please confirm if
      you wish to continue.
    </StyledSpan>
  ),
  closing: (
    <StyledSpan>
      You are about to <strong>close</strong> the work order, this will mark{" "}
      <strong>ALL</strong> routings as complete and <strong>ALL</strong>{" "}
      available quantity of parts as <strong>completed</strong>.
    </StyledSpan>
  ),
});
