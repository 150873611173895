import React from "react";

import { useGetDisplayDashboard } from "../../hooks";
import { HubTableTemplate } from "../../components";
import { condition } from "./helper";
import { StyledTwoStack } from "./styles";

import PartialCell from "./PartialCell";
import DisplayTableContainer from "./DisplayTableContainer";

const sortable = true;
const items = [
  {
    id: "startDate",
    label: "Start Date",
    sortable,
  },
  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,
  },
  {
    id: "dip",
    label: "Dip",
  },
  {
    id: "material",
    label: "Material",
  },
  {
    id: "lotNumber",
    label: "Lot",
  },
  {
    id: "available",
    label: "Available",
  },
  {
    id: "remaining",
    label: "Remaining",
  },
  {
    id: "startTime",
    label: "Start Time",
  },
];

const keys = [
  {
    value: "startDate",
    isDate: true,
    sx: {
      width: "100px",
    },
  },
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    value: "workOrder",
    variant: "blueBold",
  },
  {
    value: "departmentOrder",
    defaultValue: 1,
  },
  {
    value: "material.name",
  },
  {
    value: "lotNumber",
  },
  {
    value: "available",
    defaultValue: 0,
    CustomComponent: PartialCell,
  },
  {
    value: "remaining",
    defaultValue: 0,
  },
  {
    value: "actualStartDate",
    isDateTime: true,
  },
];

const DipTable = ({ setWorkOrder = () => {}, defaultLocation }) => {
  const { data } = useGetDisplayDashboard({ labor: false, defaultLocation });

  const onClick = ({ workOrder, rid }) => setWorkOrder({ workOrder, rid });

  const modifiedKeys = keys.map((key) =>
    key.value === "workOrder" ? { ...key, onClick } : key
  );
  const headerProps = { items, includeCheckbox: false, includeEnd: false };

  return (
    <StyledTwoStack gridTemplateColumns="1fr">
      {data?.rows
        ?.filter((station) => station?.title === "Dipping | Hand")
        ?.map((station) => {
          const props = {
            headerProps,
            bodyProps: { keys: modifiedKeys, condition, items: station?.rows },
          };
          return (
            <DisplayTableContainer
              title={station?.title}
              count={station?.count}
            >
              <HubTableTemplate id="DisplayTable" {...props} />
            </DisplayTableContainer>
          );
        })}
    </StyledTwoStack>
  );
};

export default DipTable;
