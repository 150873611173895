import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { HubIcon } from "../HubIcon";
import { HubAccordian } from "../HubAccordian";

export const StyledFormControl = styled(FormControl)(({ hidden }) => ({
  ...(hidden && {
    display: "none",
  }),
}));

export const StyledHubIcon = styled(HubIcon)(({ theme }) => ({
  color: `${theme.palette.hubColors.grey} !important`,
}));

export const StyledHubAccordian = styled(HubAccordian)(({ theme }) => ({
  "&.MuiPaper-root": {
    marginTop: "0 !important",
    boxShadow: "none !important",
    border: "0 !important",
    borderRadius: "0px !important",
    "& .MuiButtonBase-root": {
      border: 0,
      borderRadius: "0px",
      padding: "8px 14px",
      marginBottom: 0,
      fontSize: 12,
      ":hover": {
        backgroundColor: theme.palette.hubColors.greyLightest,
      },
      ".MuiAccordionSummary-content > .MuiTypography-root": {
        fontSize: 12,
      },
    },

    ".MuiAccordionDetails-root": {
      "& .MuiButtonBase-root": {
        paddingLeft: "20px",
      },
    },

    "& .MuiMenuItem-root > .MuiTypography-root": {
      fontSize: "12px !important",
    },
  },
}));
