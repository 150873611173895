import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTwoStack = styled(Stack)({
  display: "grid",
  gap: "15px",
  height: "100%",
  width: "100%",
  overflow: "hidden",
});

export const StyledDisplayStack = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: "25px",
  backgroundColor: theme.palette.hubColors.white,
  boxSizing: "border-box",
}));

export const StyledStackGrid = styled(Stack, {
  shouldForwardProp: (prop) => !["full"].includes(prop),
})(({ full = true }) => ({
  display: "grid",
  gridTemplateRows: `repeat(${full ? 2 : 1}, 1fr)`,
  gap: "15px",
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));

export const StyledGridRow = styled(Stack, {
  shouldForwardProp: (prop) => !["full"].includes(prop),
})(({ full = true }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${full ? 2 : 1}, 1fr)`,
  gap: "15px",
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));
