import React from "react";
import { Typography } from "@mui/material";

import { HubCardStack } from "../../components";
import { StyledSubStack } from "./styles";

import LaborTable from "./LaborTable";

const WorkOrderRoutingLabor = ({ routeId }) => {

  return (
    <StyledSubStack>
      <HubCardStack>
        <Typography variant="boldCaption">Work Order Routing Labor</Typography>
        <LaborTable routeId={routeId} />
      </HubCardStack>
    </StyledSubStack>
  );
};

export default WorkOrderRoutingLabor;
