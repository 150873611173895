import React from "react";
import { Typography, Stack } from "@mui/material";
import { connect } from "react-redux";

import { HubBackButton } from "../../components";

import { StyledTitleStack } from "./styles";
import CustomerOrderContextMenu from "../../tables/CustomerOrdersTable/CustomerOrderContextMenu";

const OrdersBar = ({ id, setDeletingId = () => {} }) => {
  return (
    <StyledTitleStack>
      <Stack direction="row" gap="10px" alignItems="center">
        <HubBackButton />
        <Typography variant="caption">Order</Typography>
      </Stack>

      <Stack>
        <CustomerOrderContextMenu id={id} setDeletingId={setDeletingId} />
      </Stack>
    </StyledTitleStack>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(OrdersBar);
