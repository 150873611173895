import React, { useEffect } from "react";
import { Stack, Checkbox, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
// import { useFormContext } from "react-hook-form";

import { StyledHubButton, StyledStack } from "./styles";
import {
  HubHookFormDatePicker,
  HubIcon,
  HubMaterialHookSelect,
  StationHookFormSelect,
} from "../../components";
import { useGetWorkOrderData } from "../../hooks";
import WorkOrderContextMenu from "./WorkOrderContextMenu";

import WorkOrderRoutingSubRow from "./WorkOrderRoutingSubRow";
import { handleReschedule } from "./moveHelper";
import { condition, onDateChange } from "./helper";

const hiddenValues = ["scrapped", "completed", "routeId", "leadTime", "order"];

const WorkOrderRoutingRow = ({
  item,
  idx,
  open,
  remove = () => {},
  handleRowClick = () => {},
  setRouteId = () => {},
  ...rest
}) => {
  const { id } = useParams();
  const { data } = useGetWorkOrderData(id);

  const { getValues, setValue } = useFormContext();

  const prefix = `routes.${idx}`;
  const boxSx = {
    height: "100%",
    width: "100%",
    borderRight: "1px solid",
    borderColor: "hubColors.greyLight",
    alignItems: "center",
    justifyContent: "center",
  };

  const isCurrent = item?.routeId === data?.currentRoute;
  const isDone = !!item?.endDate;
  const hasStarted = !!item?.startDate;

  const handleOnDateChange = () => {
    onDateChange(prefix, getValues, setValue);
    handleReschedule(idx, getValues, setValue);
  };

  useEffect(() => {
    hiddenValues?.forEach((key) => setValue(`${prefix}.${key}`, item[key]));
  }, [item.scrapped, item.completed, item.routeId, item.order, item.leadTime]);

  return (
    <Stack backgroundColor="hubColors.white">
      <StyledStack {...rest} {...condition({ ...item, isCurrent })}>
        <Stack sx={boxSx} direction="row" gap="2px">
          <HubIcon
            icon="MoreVertical"
            size="12px"
            sx={{ marginRight: "-10px" }}
          />
          <HubIcon icon="MoreVertical" size="12px" />
        </Stack>
        <Stack sx={boxSx}>
          <StyledHubButton
            onClick={(e) => handleRowClick(item.id)}
            label={
              <Checkbox
                sx={{ padding: 0 }}
                checked={open === item.id}
                icon={<HubIcon icon="ChevronRight" size="12px" />}
                checkedIcon={<HubIcon icon="ChevronDown" size="12px" />}
              />
            }
          />
        </Stack>
        <Stack sx={boxSx}>
          <Checkbox sx={{ padding: 0 }} checked={isDone} />
        </Stack>
        <StationHookFormSelect
          name={`${prefix}.stationId`}
          defaultValue={item?.stationId}
          groupByKey="department.label"
          useSetValue
          hideLabel
          required
        />
        <HubMaterialHookSelect
          name={`${prefix}.materialId`}
          defaultValue={item?.materialId || ""}
          useDefault={!!item?.materialId}
          required={false}
          useSetValue
          hideLabel
        />
        <Stack sx={{ ...boxSx, alignItems: "start", pl: "15px" }}>
          <Typography variant="caption">{item?.completed}</Typography>
        </Stack>
        <Stack sx={{ ...boxSx, alignItems: "start", pl: "15px" }}>
          <Typography variant="caption">{item?.scrapped}</Typography>
        </Stack>

        <HubHookFormDatePicker
          name={`${prefix}.plannedStartDate`}
          defaultValue={item?.plannedStartDate}
          onChange={handleOnDateChange}
          useSetValue
          required
        />
        <HubHookFormDatePicker
          name={`${prefix}.plannedEndDate`}
          defaultValue={item?.plannedEndDate}
          onChange={handleOnDateChange}
          useSetValue
          required
        />
        <HubHookFormDatePicker
          name={`${prefix}.startDate`}
          defaultValue={item?.startDate}
          useSetValue
        />
        <HubHookFormDatePicker
          name={`${prefix}.endDate`}
          defaultValue={item?.endDate}
          useSetValue
        />
        <WorkOrderContextMenu
          id={idx}
          routeId={item?.routeId}
          disabled={hasStarted}
          remove={remove}
          setRouteId={setRouteId}
        />
      </StyledStack>

      {open === item.id && item?.routeId && (
        <WorkOrderRoutingSubRow
          routeId={item?.routeId}
          isOpen={open === item.id}
        />
      )}
    </Stack>
  );
};

export default WorkOrderRoutingRow;
