import React, { useMemo } from "react";
import { useGetEmployeeList } from "../../hooks";
import { HubHookFormSelect } from "../HubHookFormSelect";

export const EmployeeHookFormSelect = ({
  name = "employeeId",
  label = "Employee",
  hideLabel = false,
  dispatch = () => {},
  required = true,
  disabled = false,
  location,
  ...rest
}) => {
  const { data, isLoading } = useGetEmployeeList(location);
  const options = useMemo(
    () => data?.map((e) => ({ id: e.id, label: e.name })) || [],
    [data]
  );

  return (
    <HubHookFormSelect
      name={name}
      options={options}
      required={required}
      disabled={disabled || isLoading}
      {...(!hideLabel ? { label } : {})}
      {...rest}
    />
  );
};

export default EmployeeHookFormSelect;
