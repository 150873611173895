import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { EmployeeHookFormSelect } from "../../components";
import { useGetEmployeeList } from "../../hooks";
import { useScannerHelper } from "../../helpers";

const CreateLaborContentEmployeeId = ({ locationId = 1 }) => {
  const { setValue, watch } = useFormContext();
  const { data } = useGetEmployeeList(locationId);
  const [scanned, setScanned] = useState(false);

  const employeeId = watch("employeeId");

  useEffect(() => {
    if (!employeeId || !scanned) return;

    const submitButton = document.getElementById("ModalDisplaySubmit");
    if (submitButton) submitButton.click();
  }, [employeeId, scanned]);

  const setEmployeeId = (barcode) => {
    const employee = data?.find((emp) => emp?.employeeId === barcode);
    console.log({ employee, barcode, data });
    if (employee?.id) {
      setValue("employeeId", employee.id);
      setScanned(true);
    }
  };

  useScannerHelper(setEmployeeId);

  return (
    <EmployeeHookFormSelect
      location={locationId}
      onChange={() => setScanned(false)}
    />
  );
};

export default CreateLaborContentEmployeeId;
