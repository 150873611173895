import React, { useEffect, useState } from "react";
import { TableContainer, Table } from "@mui/material";

import { useDeleteLaborData } from "../../hooks";
import { WarningDeleteDialog } from "../../dialogs";

import LaborTableHeader from "./LaborTableHeader";
import LaborTableBody from "./LaborTableBody";

export const LaborTable = ({ routeId }) => {
  const { mutate: deleteLaborData, isSuccess } = useDeleteLaborData();

  useEffect(() => {
    isSuccess && window.location.reload();
  }, [isSuccess]);

  const [deletingId, setDeletingId] = useState(false);
  const handleDeleteClose = () => setDeletingId(false);
  const handleDeleteSubmit = () => {
    if (deletingId) {
      deleteLaborData(deletingId);
      handleDeleteClose();
    }
  };

  return (
    <TableContainer sx={{ backgroundColor: "hubColors.white" }}>
      <Table id="CustomerOrdersTable">
        <LaborTableHeader />
        <LaborTableBody routeId={routeId} setDeletingId={setDeletingId} />
      </Table>
      <WarningDeleteDialog
        isOpen={!!deletingId}
        onClose={handleDeleteClose}
        onSubmit={handleDeleteSubmit}
        title="You are about to delete a labor ticket!"
      />
    </TableContainer>
  );
};

export default LaborTable;
