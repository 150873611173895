import { ROLES } from "./roles";

const hidden = true;
export const MENU_ITEMS = [
  { id: 0, label: "Models", path: "/models" },
  { id: 1, label: "Customers", path: "/customers", hidden },
  { id: 2, label: "Parts", path: "/parts", hidden },
  { id: 3, label: "Orders", path: "/orders", hidden },
  { id: 4, label: "Work Orders", path: "/works", hidden },
  { id: 5, label: "Displays", path: "/displays" },
  { id: 6, label: "Labor", path: "/labor", hidden },
  { id: 7, label: "Tickets", path: "/tickets" },
  { id: 8, label: "Organization", path: "/organization", hidden },
];

const displayOnly = (m) => m.id >= 5;
export const getUserMenuItems = (role, includeHidden = true) => {
  const ITEMS =
    role === ROLES.Generic ? MENU_ITEMS.filter(displayOnly) : MENU_ITEMS;
  const FINAL = includeHidden ? ITEMS : ITEMS?.filter((i) => !i.hidden);
  return FINAL;
};
