import React from "react";
import { Stack } from "@mui/material";

import { HubCardStack, SideNavBar } from "../../components";
import { StyledTitleStack } from "../PartDetails/styles";

import { StyledSkeleton, StyleDisplayStack, StyledCardStack } from "./styles";

const WorkOrderSkeleton = () => {
  return (
    <Stack height="100%" overflow="hidden">
      <StyledTitleStack height="51px">
        <StyledSkeleton variant="rounded" height={30} width={177} />
        <StyledSkeleton variant="rounded" height={30} width={441} />
      </StyledTitleStack>
      <StyleDisplayStack>
        <SideNavBar />
        <Stack
          padding="15px"
          overflow="auto"
          sx={{
            "&::-webkit-scrollbar": { display: "none !important" },
          }}
        >
          <HubCardStack padding="20px" gap="15px">
            <StyledSkeleton variant="rounded" height={27.5} />
            <Stack gap="2px">
              <StyledSkeleton variant="rounded" height={32} />
              {Array.from(new Array(15)).map((_, rowIndex) => (
                <StyledSkeleton
                  key={rowIndex}
                  variant="rounded"
                  height={36.5}
                />
              ))}
            </Stack>
          </HubCardStack>
        </Stack>
        <StyledCardStack>
          {Array.from(new Array(5)).map((_, rowIndex) => (
            <StyledSkeleton
              key={`details-${rowIndex}`}
              variant="rounded"
              height="100%"
              width="100%"
            />
          ))}
        </StyledCardStack>
      </StyleDisplayStack>
    </Stack>
  );
};

export default WorkOrderSkeleton;
