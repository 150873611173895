import React, { useMemo, useState } from "react";

import { HubHookFormWrapper } from "../../components";
import WorkOrderMain from "./WorkOrderMain";
import WorkOrderBar from "./WorkOrderBar";
import WorkOrderNav from "./WorkOrderNav";

import { StyleDisplayStack } from "./styles";
import { tabs, updateMapper } from "./helper";

const formSx = { height: "100%", overflow: "hidden" };

const WorkOrderContent = ({ id, data, setPayload, handleCloseWorkOrder }) => {
  const [value, setValue] = useState(1);

  const rows = useMemo(
    () =>
      data?.routing?.map((r, order) => ({
        routeId: r.id,
        stationId: r.station.id,
        materialId: r.materialId || "",
        completed: r.completed || 0,
        scrapped: r.scrapped || 0,
        plannedStartDate: r.plannedStartDate,
        plannedEndDate: r.plannedEndDate,
        startDate: r.startDate,
        endDate: r.endDate,
        leadTime: r.leadTime || 0,
        order,
      })) || [],
    [data?.routing]
  );

  const onFormSubmit = (formData) => {
    const updatePayload = updateMapper(formData, data, rows, id);
    setPayload(updatePayload);
  };

  const DisplayTab = useMemo(
    () => tabs.find((t) => t.id === value)?.TabElement || <></>,
    [value]
  );

  return (
    <HubHookFormWrapper
      onSubmit={onFormSubmit}
      sx={formSx}
      defaultValues={{
        routes: rows,
        routing: data.currentRoute,
        status: data.state,
      }}
    >
      <WorkOrderBar
        workOrder={data?.workOrder}
        onCloseWorkOrder={handleCloseWorkOrder}
        status={data?.status}
        state={data?.state}
      />
      <StyleDisplayStack>
        <WorkOrderNav value={value} setValue={setValue} />
        <DisplayTab id={id} rows={rows} />
        <WorkOrderMain />
      </StyleDisplayStack>
    </HubHookFormWrapper>
  );
};

export default WorkOrderContent;
