import React from "react";
import { Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import { useFieldArray, useFormContext } from "react-hook-form";

import { getPartRouteByName } from "../../../redux/routing";
import { HubButton, HubHookFormInput } from "../../../components";
import { PostFoundryTable } from "../../../tables";
import { StyledHubCardStack } from "../styles";

const gap = "10px";

const PartPostFoundry = () => {
  const { getValues } = useFormContext();
  const details = getValues("post");

  const fieldArrayOptions = useFieldArray({
    name: "post.routes",
  });
  const { append } = fieldArrayOptions;

  const handleAdd = () => {
    append({
      isOutside: false,
      stationId: null,
      partnerId: null,
      leadTime: 1440,
      cycleTime: 0,
      notes: "",
    });
  };

  return (
    <StyledHubCardStack gap="25px">
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Post Foundry Routing</Typography>
        <PostFoundryTable {...fieldArrayOptions} />
        <HubButton
          label="Add Routing"
          variant="text"
          sx={{ fontSize: 10, ml: "auto", mb: -5 }}
          onClick={handleAdd}
        />
      </Stack>
      <Stack gap={gap}>
        <Typography variant="boldCaptionMain">Post Foundry Details</Typography>
        <HubHookFormInput
          name="post.notes"
          label="Special Instructions"
          placeholder="Post Foundry notes..."
          defaultValue={details?.notes}
          rows={5}
          multiline
        />
        {details?.id && (
          <HubHookFormInput name="post.id" defaultValue={details?.id} hidden />
        )}
      </Stack>
    </StyledHubCardStack>
  );
};

const mapStateToProps = (state) => ({
  details: getPartRouteByName(state, "post"),
});

export default connect(mapStateToProps)(PartPostFoundry);
