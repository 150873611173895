import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";

import { AdminCreateLaborDialog } from "../../dialogs";
import { WorkOrderRoutingHeader } from "./WorkOrderRoutingHeader";
import WorkOrderRoutingTableBody from "./WorkOrderRoutingTableBody";
import WorkOrderRouteAddRow from "./WorkOrderRouteAddRow";
import { connect } from "react-redux";
import { setIsOpenDialog } from "../../redux";

const WorkOrderRoutingTable = ({ _setIsOpenDialog = () => {}, ...props }) => {
  const [routeId, setRouteId] = useState(null);

  const handleOpen = (val = true) =>
    _setIsOpenDialog("admin-create-labor-ticket", val);
  const handleClose = () => {
    handleOpen(false);
    setRouteId(null);
  };

  useEffect(() => {
    if (routeId) handleOpen(true);
  }, [routeId]);

  return (
    <Stack overflowX="auto" width="100%">
      <WorkOrderRoutingHeader />
      <WorkOrderRoutingTableBody setRouteId={setRouteId} {...props} />
      <WorkOrderRouteAddRow {...props} />
      <AdminCreateLaborDialog routeId={routeId} onClose={handleClose} />
    </Stack>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  _setIsOpenDialog: setIsOpenDialog,
};
export const WorkOrderRoutingTableV2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderRoutingTable);
export default WorkOrderRoutingTableV2;
