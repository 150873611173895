import React from "react";
import { Stack, Typography } from "@mui/material";

const RoutingRenderElement = ({ label, material, departmentOrder }) => {
  const sx = { fontSize: "12px !important" };
  return (
    <Stack direction="row" gap="5px">
      <Typography sx={sx}>{label}</Typography>
      {material && (
        <Typography
          sx={{ ...sx, color: "hubColors.mainFocus", fontWeight: 500 }}
        >
          {material}
        </Typography>
      )}
      {departmentOrder && (
        <Typography
          sx={{ ...sx, color: "hubColors.mainFocus", fontWeight: 500 }}
        >
          # {departmentOrder}
        </Typography>
      )}
    </Stack>
  );
};

export default RoutingRenderElement;
