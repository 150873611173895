import { useQuery } from "react-query";
import api from "../../utils/api";

const STATIONS = "/station";

const getStationList = () => {
  return api.get(`${STATIONS}/all`);
};
export const useGetStationList = (enabled) => {
  return useQuery([STATIONS], getStationList, {
    enabled,
  });
};
