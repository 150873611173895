import React from "react";
import { HubContextMenu } from "../../components";

const MENU_ITEMS = [
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

const LaborContextMenu = ({ id, setDeletingId = () => {}, ...rest }) => {
  const onSelect = (item) => {
    switch (item?.id) {
      case "delete":
        setDeletingId(id);
        break;
      default:
        break;
    }
  };

  return (
    <HubContextMenu
      id={"labor"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{ sx: { padding: 0 } }}
      HubIconProps={{ size: 14 }}
      {...rest}
    />
  );
};

export default LaborContextMenu;
