import React, { useEffect, useMemo } from "react";

import { useGetWorkOrderRouting } from "../../hooks";
import { HubHookFormSelect } from "../HubHookFormSelect";

import RoutingRenderElement from "./RoutingRenderElement";
import { useFormContext } from "react-hook-form";

export const WorkOrderRoutingSelect = ({
  workOrder = "",
  name = "routingId",
  label = "Work Order Routes",
  disabled = false,
  hideLabel,
  routeId,
  ...rest
}) => {
  const { data, isLoading } = useGetWorkOrderRouting(workOrder);
  const defaultValue = routeId || data?.selected;
  const options = data?.options || [];
  const isDisabled = isLoading || disabled;

  const { setValue } = useFormContext();

  useEffect(() => {
    const defaultValue = routeId || data?.selected;
    setValue(name, defaultValue);
  }, [data, routeId]);

  return (
    <HubHookFormSelect
      name={name}
      options={options}
      defaultValue={defaultValue}
      groupByKey="status"
      defaultOpenGroup="Open"
      RenderComponent={RoutingRenderElement}
      renderValue={(value) => {
        const op = options?.find((o) => o.id === value);
        return <RoutingRenderElement {...op} />;
      }}
      disabled={isDisabled}
      required
      {...(!hideLabel ? { label } : {})}
      {...rest}
    />
  );
};

export default WorkOrderRoutingSelect;
