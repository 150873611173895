import { useEffect } from "react";

export const useScannerHelper = (onScan = () => {}, dependency = []) => {
  useEffect(() => {
    let barcode = "";

    const handleKeyDown = (event) => {
      if (["Enter", "Tab"].includes(event.key)) {
        onScan(barcode);
        barcode = "";
      } else if (/^[a-zA-Z0-9\-]$/.test(event.key)) {
        barcode += event.key;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onScan, ...dependency]);
};
