import React from "react";
import { Collapse } from "@mui/material";
import WorkOrderRoutingLabor from "../WorkOrderRoutingTable/WorkOrderRoutingLabor";
import { useGetLaborDataByRouteId } from "../../hooks";

const WorkOrderRoutingSubRow = ({ routeId, isOpen }) => {
  const getRoute = isOpen ? routeId : false;
  const { isLoading } = useGetLaborDataByRouteId(getRoute);

  return (
    <Collapse in={isOpen && !isLoading && routeId}>
      <WorkOrderRoutingLabor routeId={getRoute} />
    </Collapse>
  );
};

export default WorkOrderRoutingSubRow;
