import { useQuery } from "react-query";
import qs from "qs-lite";
import api from "../../utils/api";
import { getQueryParams } from "../../helpers";

const MATERIALS = "/materials";

const TREES = "/trees";

const getMaterialData = () => api.get(`${MATERIALS}/all`);
export const useGetMaterialData = (enabled) => {
  return useQuery([MATERIALS], getMaterialData, {
    keepPreviousData: true,
    enabled,
  });
};

const getAllMaterialData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${MATERIALS}?${queryString}`);
};
export const useGetAllMaterialData = () => {
  const queryProps = getQueryParams({ prefix: "material" });
  return useQuery([MATERIALS, queryProps], getAllMaterialData, {
    keepPreviousData: true,
  });
};

const getAllTreeData = ({ queryKey }) => {
  const queryString = qs.stringify(queryKey[1]);
  return api.get(`${TREES}?${queryString}`);
};

export const useGetAllTreeData = () => {
  const queryProps = getQueryParams({ prefix: "tree" });
  return useQuery([TREES, queryProps], getAllTreeData, {
    retry: false,
    keepPreviousData: true,
  });
};
