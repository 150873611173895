import dayjs from "dayjs";

import { differenceInBusinessDays, isValid } from "date-fns";
import utc from "dayjs/plugin/utc";
import { useTheme } from "@mui/material";

export const condition = ({
  isCurrent = false,
  plannedStartDate = "",
  startDate = "",
  plannedEndDate = "",
  endDate = "",
}) => {
  const startLogic =
    dayjs(plannedStartDate).utc() <
    (startDate ? dayjs(startDate) : dayjs()).utc();

  const endLogic =
    dayjs(plannedEndDate).utc() < (endDate ? dayjs(endDate) : dayjs()).utc();

  const color = "rgba(239, 55, 55,";
  const isActive = (startDate && !endDate) || isCurrent;
  const addRed = (startLogic && !endDate) || (startLogic && endLogic);

  return {
    sx: {
      "&.MuiStack-root": {
        padding: "0px",
        ">.MuiTypography-root": {
          // marginLeft: "13px",
        },
        ":hover": {
          backgroundColor: "hubColors.greyLightest",
        },
        backgroundColor: "hubColors.white",
        ...(isActive ? { backgroundColor: "hubColors.mainFocusLighter2" } : {}),
        ...(addRed
          ? {
              borderColor: `${color} 0.2) !important`,
              backgroundColor: `${color} ${isActive ? "0.15" : "0.075"})`,
              ">*, .MuiInputBase-root": {
                borderColor: `${color} 0.2) !important`,
              },
            }
          : {
              "& .MuiTableCell-root": {
                padding: "0px",
              },
            }),
      },
    },
  };
};

export const onDragEnd = (result, items, setItems) => {
  if (!result.destination) return; // Exit if dropped outside a valid area

  const { source, destination } = result;

  // Create a new array with reordered elements
  const reorderedItems = Array.from(items);
  const [movedItem] = reorderedItems.splice(source.index, 1);
  reorderedItems.splice(destination.index, 0, movedItem);

  // Map and update order attribute to reflect new order
  const updatedItems = reorderedItems.map((item, index) => ({
    ...item,
    order: index + 1, // Adjust order numbering (1-based index)
  }));

  setItems(updatedItems); // Update state
};

export default onDragEnd;

// Function to calculate business days between two dates
const calculateBusinessDays = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  if (!isValid(startDate) || !isValid(endDate)) return 0;
  const days = differenceInBusinessDays(endDate, startDate) * 1440;
  return days;
};

export const onDateChange = (prefix, getValues, setValue) => {
  const plannedStartDate = getValues(`${prefix}.plannedStartDate`);
  const plannedEndDate = getValues(`${prefix}.plannedEndDate`);

  const newLead = calculateBusinessDays(plannedStartDate, plannedEndDate);
  setValue(`${prefix}.leadTime`, newLead);

  console.log({ newLead });

  if (newLead === 0) {
    setValue(`${prefix}.plannedEndDate`, value);
    setValue(`${prefix}.plannedStartDate`, value);
  }
};

export const getItemStyle = (isDragging, draggableStyle, theme) => ({
  backgroundColor: theme.palette.hubColors.white,
  borderRadius: "10px",
  zIndex: 1,
  ...draggableStyle,
  ...(isDragging && {}),
});

const queryAttr = "data-rbd-drag-handle-draggable-id";

export const onDragUpdate = ({ update, onChange }) => {
  if (!update.destination) {
    return;
  }
  const draggableId = update.draggableId;
  const destinationIndex = update.destination.index;

  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);

  if (!draggedDOM) {
    return;
  }
  const { clientHeight, clientWidth } = draggedDOM;

  const clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    [...draggedDOM.parentNode.children]
      .slice(0, destinationIndex)
      .reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

  onChange({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(
      window.getComputedStyle(draggedDOM.parentNode).paddingLeft
    ),
  });
};
