import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { useGetPartData, usePutPartData } from "../../hooks";
import { getPartRoutings, loadPartRoutings } from "../../redux/routing";
import { HubHookFormWrapper, PageContent } from "../../components";

import PartDetailsTitle from "./PartDetailsTitle";
import { PART_SECTIONS, UpdateMapper } from "./helper";
import PartDetailsMenu from "./PartDetailsMenu";
import { StyledCardAndMenuStack, StyledDisplayStack } from "./styles";
import PartDetailsSkeleton from "./PartDetailsSkeleton";

const formSx = { height: "100%", overflow: "hidden" };

export const PartDetails = ({
  _loadPartRoutings = () => {},
  routings = [],
}) => {
  const { id } = useParams();
  const { data, isLoading, isSuccess, refetch } = useGetPartData({ id });
  const { mutate: putPartData } = usePutPartData();

  const [value, setValue] = useState("ROUTING");

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    if (isSuccess) _loadPartRoutings(data);
  }, [isSuccess, data]);

  const tabProps = { value, setValue };
  const ActiveTab = PART_SECTIONS[value] || <></>;

  const handleSubmit = (formData) => {
    if (value === "ROUTING") {
      const payload = UpdateMapper(formData, routings, id);
      putPartData(payload);
    }
  };

  return (
    <PageContent
      isLoading={isLoading}
      skeleton={<PartDetailsSkeleton />}
      disablePadding
    >
      <HubHookFormWrapper
        onSubmit={handleSubmit}
        sx={formSx}
        defaultValues={data}
        key={id}
      >
        <PartDetailsTitle id={id} />
        <StyledCardAndMenuStack>
          <PartDetailsMenu {...tabProps} />
          <StyledDisplayStack>{ActiveTab}</StyledDisplayStack>
        </StyledCardAndMenuStack>
      </HubHookFormWrapper>
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  routings: getPartRoutings(state),
});
const mapDispatchToProps = {
  _loadPartRoutings: loadPartRoutings,
};

export const PartDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartDetails);

export default PartDetailsPage;
