// import PartQuoting from "./forms/PartQuoting";
import { PartCustomers } from "../../containers";
import PartRouting from "./forms/PartRouting";

export const tabs = [
  {
    id: "ROUTING",
    icon: "Settings",
    label: "Routing",
  },
  {
    id: "WORKS",
    icon: "Layout",
    label: "Work Orders",
  },
];

export const PART_SECTIONS = {
  ROUTING: <PartRouting />,
  QUOTE: <></>,
  HISTORY: <></>,
  WORKS: <></>,
  CUSTOMERS: <PartCustomers />,
};

export const UpdateMapper = (formData, routings = [], id) => {
  const sections = [
    "wax",
    "assembly",
    "dip",
    "autoclave",
    "foundry",
    "post",
    "inspection",
  ];
  const removeStation = ["assembly", "autoclave", "foundry", "inspection"];

  const mappedData = routings.reduce((acc, idx) => {
    const section = sections[idx - 1];
    acc[section] = { ...formData[section] };
    if (removeStation.includes(section)) delete acc[section].stationId;
    return acc;
  }, {});

  const details = formData?.details;

  return {
    id,
    details: {
      partName: details?.partName,
      partNumber: details?.partNumber,
      materialId: details?.materialId,
      treeId: details?.treeId,
      partsPerTree: details?.partsPerTree,
      weight: details?.weight,
      lastSentPrice: details?.lastSentPrice,
      status: details?.status,
      customerIds: details?.customerIds?.map(({ id }) => id),
    },
    ...mappedData,
  };
};
