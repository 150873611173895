import React from "react";
import { Box } from "@mui/material";
import { HubTableHeader, InputLabel } from "../../components";
import { StyledStack } from "./styles";

const required = true;

export const items = [
  {
    id: "drag",
    label: "",
  },
  {
    id: "open",
    label: "",
  },
  {
    id: "complete",
    label: "",
  },
  {
    id: "station",
    label: "Station",
    required,
  },
  {
    id: "material",
    label: "Material",
  },
  {
    id: "completed",
    label: "Completed",
  },
  {
    id: "scrapped",
    label: "Scrapped",
  },
  {
    id: "plannedStartDate",
    label: "Planned Start",
    required,
  },
  {
    id: "plannedEndDate",
    label: "Planned End",
    required,
  },
  {
    id: "startDate",
    label: "Actual Start",
  },
  {
    id: "endDate",
    label: "Actual End",
  },
];

export const WorkOrderRoutingHeader = () => {
  return (
    <StyledStack sx={{ backgroundColor: "hubColors.greyLighter" }}>
      {items?.map((cell, idx) => (
        <Box
          key={idx}
          sx={{
            borderRight: "1px solid",
            borderColor: "hubColors.greyLight",
            padding: "4px 5px",
            height: "100%",
          }}
        >
          <InputLabel
            key={cell.id}
            label={cell.label}
            required={cell.required}
            marginLeft="5px"
            marginBottom="0px"
            sx={{
              textWrap: "nowrap",
            }}
          />
        </Box>
      ))}
    </StyledStack>
  );
};
