import { addDays, isWeekend } from "date-fns";
import { useFormContext } from "react-hook-form";

/**
 * Adds business days (Monday-Friday), skipping weekends.
 * @param {Date} date - The start date
 * @param {number} days - Number of business days to add
 * @returns {Date} - The resulting business day
 */
export const addBusinessDays = (date, days) => {
  let result = new Date(date);
  let addedDays = 0;
  const increments = Math.floor(days / 1440);

  while (addedDays < increments) {
    result = addDays(result, 1);
    if (!isWeekend(result)) {
      addedDays++;
    }
  }

  return new Date(result).toISOString();
};

/**
 * Updates the planned start and end dates for all rows after a move.
 * @param {Array} fields - The array of work order routing rows
 * @param {number} movedIndex - The index of the moved row
 * @returns {Array} - Updated fields with recalculated dates
 */
export const updatePlannedDates = (fields, movedIndex) => {
  if (fields.length === 0) return fields;

  const updatedFields = [...fields];

  // Determine the new plannedStartDate for the moved row
  const previousRow =
    movedIndex > 0 ? updatedFields[movedIndex - 1] : updatedFields[1];
  updatedFields[movedIndex].plannedStartDate = previousRow.plannedEndDate;

  // Calculate plannedEndDate based on leadTime (excluding weekends)
  updatedFields[movedIndex].plannedEndDate = addBusinessDays(
    updatedFields[movedIndex].plannedStartDate,
    updatedFields[movedIndex].leadTime || 0
  );

  // Update all subsequent rows
  for (let i = movedIndex + 1; i < updatedFields.length; i++) {
    updatedFields[i].plannedStartDate = updatedFields[i - 1].plannedEndDate;
    updatedFields[i].plannedEndDate = addBusinessDays(
      updatedFields[i].plannedStartDate,
      updatedFields[i].leadTime || 0
    );
  }

  return updatedFields;
};

export const handleReschedule = (
  end,
  getValues = () => {},
  setValue = () => {}
) => {
  const routes = getValues("routes");
  const rows = Object.values(routes);

  // Update planned dates after moving
  const updatedFields = updatePlannedDates(rows, end);

  // Apply updated values to the form
  updatedFields.forEach((field, index) => {
    setValue(`routes.${index}.plannedStartDate`, field.plannedStartDate);
    setValue(`routes.${index}.plannedEndDate`, field.plannedEndDate);
  });
};
