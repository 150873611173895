import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import {
  HubCancelSubmitGroup,
  HubDialogContent,
  HubHookFormWrapper,
} from "../../components";
import AdminCreateLaborContentForm from "./AdminCreateLaborContentForm";
import { usePostAdminCreateLabor } from "../../hooks";

const AdminCreateLaborContent = ({ routeId, onClose = () => {} }) => {
  const { id } = useParams();
  const { mutate: postLabor, isLoading, isSuccess } = usePostAdminCreateLabor();

  const getIsoDate = (val) => new Date(val).toISOString();

  useEffect(() => {
    isSuccess && window.location.reload();
  }, [isSuccess]);

  const onSubmit = (formData) => {
    const payload = {
      routeId: formData.routingId,
      employeeId: formData.employeeId,
      completed: Number(formData.completed) || 0,
      scrapped: Number(formData.scrapped) || 0,
      startDate: getIsoDate(formData.startDate),
      ...(formData.endDate ? { endDate: getIsoDate(formData.endDate) } : {}),
    };
    postLabor(payload);
  };

  return (
    <HubDialogContent largePadding>
      <HubHookFormWrapper onSubmit={onSubmit}>
        <Stack gap="35px">
          <Stack gap="10px" alignItems="center">
            <Typography variant="h6">
              Admin Create a New Labor Ticket
            </Typography>
            <Typography
              variant="caption"
              sx={{ textAlign: "center", maxWidth: "450px" }}
            >
              You are about to admin create a new labor ticket for work order{" "}
              <strong>{id}</strong>
            </Typography>
          </Stack>
          <AdminCreateLaborContentForm routeId={routeId} workOrder={id} />
          <HubCancelSubmitGroup
            disabled={isLoading}
            isLoading={isLoading}
            label="Create Ticket"
            overrideCancel={onClose}
          />
        </Stack>
      </HubHookFormWrapper>
    </HubDialogContent>
  );
};

export default AdminCreateLaborContent;
