import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Stack,
  // useTheme
} from "@mui/material";
import { useFormContext } from "react-hook-form";

import { useHubSnackBar } from "../../hooks";

import WorkOrderRoutingRow from "./WorkOrderRoutingRow";
import { handleReschedule } from "./moveHelper";
// import { getItemStyle, onDragUpdate } from "./helper";
// import { StyledPlaceholderDiv } from "./styles";

const WorkOrderRoutingTableBody = ({
  fields = [],
  remove = () => {},
  move = () => {},
  setRouteId = () => {},
}) => {
  const { addSnackbarError } = useHubSnackBar();
  const { getValues, setValue } = useFormContext();
  // const theme = useTheme();

  const [open, setOpen] = useState(false);
  // const [placeholderProps, setPlaceholderProps] = useState(false);

  // const handleOnDragUpdate = (update) =>
  //   onDragUpdate({ update, onChange: setPlaceholderProps });

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const start = result.source.index;
    const end = result.destination.index;

    const startItem = fields[start];
    const endItem = fields[end];

    const hasStarted = !!startItem.startDate && !!endItem.startDate;

    if (hasStarted) {
      addSnackbarError({
        message: "Cannot reorder a routing that has started",
      });
    } else {
      move(start, end);
      handleReschedule(end, getValues, setValue);
    }

    // setPlaceholderProps(false);
  };

  const handleRowClick = (id) => {
    setOpen(open === id ? null : id);
  };

  return (
    <DragDropContext
      onDragEnd={handleOnDragEnd}
      // onDragUpdate={handleOnDragUpdate}
    >
      <Droppable droppableId="wo-routing">
        {(provided) => (
          <Stack
            position="relative"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {fields?.map((item, idx) => {
              const key = item.id;
              const props = {
                idx,
                item,
                open,
                handleRowClick,
                remove,
                setRouteId,
              };
              return (
                <Draggable key={key} draggableId={key} index={idx}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      // style={getItemStyle(
                      //   snapshot.isDragging,
                      //   provided.draggableProps.style,
                      //   theme
                      // )}
                    >
                      <WorkOrderRoutingRow {...props} />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            {/* <div style={StyledPlaceholderDiv(placeholderProps, theme)} /> */}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default WorkOrderRoutingTableBody;
