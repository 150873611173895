import React, { useEffect } from "react";

import { useGetStationList } from "../../hooks";
import { getCachedItem, setCachedItem } from "../../helpers";

import { HubHookFormTagInput } from "../HubHookFormTagInput";
import { HubHookFormSelect } from "../HubHookFormSelect";

export const StationHookFormSelect = ({
  name = "stationId",
  label = "Station",
  hideLabel = false,
  required = true,
  disabled = false,
  department,
  status,
  customFilter,
  multiple = false,
  ...rest
}) => {
  const cachedData = getCachedItem("stations");
  const { data, isLoading, isSuccess } = useGetStationList(!cachedData);
  const stations = cachedData || data || [];

  useEffect(() => {
    if (data && isSuccess) setCachedItem("stations", data);
  }, [data]);

  const filterFunction = customFilter
    ? (o) => customFilter(o)
    : (o) => {
        const departmentMatch = department
          ? o.department.id === department
          : true;
        const statusMatch = status ? o.status === status : true;
        return departmentMatch && statusMatch;
      };

  const filtered =
    customFilter || department || status
      ? stations.filter(filterFunction)
      : stations;

  const FieldComponent = multiple ? HubHookFormTagInput : HubHookFormSelect;

  return (
    <FieldComponent
      name={name}
      options={filtered}
      required={required}
      disabled={disabled || isLoading}
      {...(!hideLabel ? { label } : {})}
      {...rest}
    />
  );
};

export default StationHookFormSelect;
