import React from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import {
  EmployeeHookFormSelect,
  HubHookFormDatePicker,
  HubHookFormInput,
  WorkOrderRoutingSelect,
} from "../../components";
import { useGetWorkOrderData } from "../../hooks";

const AdminCreateLaborContentForm = ({ routeId, workOrder }) => {
  const { id } = useParams();
  const { data } = useGetWorkOrderData(id);
  const defaultStart = new Date().toISOString();

  return (
    <Stack gap="15px" width="100%">
      <WorkOrderRoutingSelect workOrder={workOrder} routeId={routeId} />
      <Stack direction="row" gap="15px">
        <EmployeeHookFormSelect name="employeeId" location={data?.locationId} />
        <HubHookFormInput
          name={"completed"}
          label="Completed"
          placeholder="Completed"
          required
          isNumber
        />
        <HubHookFormInput
          name={"scrapped"}
          label="Scrapped"
          placeholder="Scrapped"
          required
          isNumber
        />
      </Stack>
      <Stack direction="row" gap="15px">
        <HubHookFormDatePicker
          name={"startDate"}
          label="Start Date"
          defaultValue={defaultStart}
          placeholder="MM/DD/YYYY"
          required
        />
        <HubHookFormDatePicker
          name={"endDate"}
          label="End Date"
          placeholder="MM/DD/YYYY"
        />
      </Stack>
    </Stack>
  );
};

export default AdminCreateLaborContentForm;
