import React, { useState } from "react";
import { FormHelperText, Stack } from "@mui/material";
import { useFormState } from "react-hook-form";

import { HubHookFormInput, HubButton, InputLabel } from "../";
import { StyledHubButton } from "./styles";

export const HubHookFormNumberInput = ({
  label = "",
  required = false,
  defaultValue = 0,
  name,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleClick = (add) => {
    const newValue = value + add;
    setValue(newValue);
  };

  const { errors } = useFormState();

  const onChange = (e, newValue) => setValue(parseInt(newValue));

  const errorMessage = errors[name]?.message;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap="10px"
      alignItems="center"
      width="100%"
    >
      <InputLabel label={label} required={required} disableMargin />
      <Stack>
        <Stack
          direction="row"
          gap="5px"
          justifyContent="center"
          alignItems="center"
        >
          <StyledHubButton
            label="-"
            variant="outlined"
            onClick={() => handleClick(-1)}
          />
          <HubHookFormInput
            name={name}
            required={required}
            defaultValue={value}
            onChange={onChange}
            isNumber
            {...rest}
          />
          <StyledHubButton
            label="+"
            variant="contained"
            onClick={() => handleClick(1)}
          />
        </Stack>
        {errorMessage && (
          <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
        )}
      </Stack>
    </Stack>
  );
};

export default HubHookFormNumberInput;
