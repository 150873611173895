import React from "react";

import { HubTableHeader, InputLabel } from "../../components";

const required = true;

const headCells = [
  {
    id: "status",
    label: "Status",
  },
  {
    id: "employee",
    label: "Employee",
    required,
  },
  {
    id: "completed",
    label: "Completed",
    required,
  },
  {
    id: "scrapped",
    label: "Scrapped",
    required,
  },
  {
    id: "startDate",
    label: "Start Date",
    required,
  },
  {
    id: "endDate",
    label: "End Date",
    required,
  },
  {
    id: "notes",
    label: "Notes",
  },
];

export const LaborTableHeader = () => {
  const ITEMS = headCells.map((cell) => ({
    id: cell.id,
    label: cell?.required ? (
      <InputLabel label={cell.label} required disableMargin />
    ) : (
      cell.label
    ),
  }));
  return (
    <HubTableHeader
      items={ITEMS}
      includeCheckbox={false}
      sx={{ backgroundColor: "hubColors.greyLighter" }}
    />
  );
};

export default LaborTableHeader;
