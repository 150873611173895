import React from "react";
import { Stack } from "@mui/material";
import {
  StyledCardAndMenuStack,
  StyledDisplayStack,
  StyledHubCardStack,
  StyledSkeleton,
  StyledTitleStack,
} from "./styles";
import { SideNavBar } from "../../components";

const FieldSkeleton = () => (
  <Stack gap="7px" width="100%">
    <StyledSkeleton variant="rounded" height={16.5} width="100px" />
    <StyledSkeleton variant="rounded" height={35.81} />
  </Stack>
);

const PartDetailsSkeleton = () => {
  return (
    <Stack height="100%" overflow="hidden">
      <StyledTitleStack height="51px">
        <StyledSkeleton variant="rounded" height={30} width={180} />
        <StyledSkeleton variant="rounded" height={30} width={260} />
      </StyledTitleStack>
      <StyledCardAndMenuStack>
        <SideNavBar />
        <StyledDisplayStack>
          <StyledHubCardStack>
            <Stack gap="25px">
              <Stack gap="15px">
                <StyledSkeleton variant="rounded" height={18} />
                <Stack gap="10px" direction="row">
                  <FieldSkeleton />
                  <FieldSkeleton />
                </Stack>
                <Stack gap="10px" direction="row">
                  <FieldSkeleton />
                  <FieldSkeleton />
                  <FieldSkeleton />
                </Stack>
                <Stack gap="10px" direction="row">
                  <FieldSkeleton />
                  <FieldSkeleton />
                  <FieldSkeleton />
                </Stack>
              </Stack>
              <Stack gap="15px">
                <StyledSkeleton variant="rounded" height={18} />
                <Stack gap="2px">
                  <StyledSkeleton variant="rounded" height={30.5} />
                  {Array.from(new Array(7)).map((_, rowIndex) => (
                    <StyledSkeleton
                      key={rowIndex}
                      variant="rounded"
                      height={33.33}
                    />
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </StyledHubCardStack>
        </StyledDisplayStack>
      </StyledCardAndMenuStack>
    </Stack>
  );
};

export default PartDetailsSkeleton;
