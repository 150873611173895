import React from "react";
import { Stack, Typography } from "@mui/material";

import {
  HubCardStack,
  HubHookFormSelect,
  HubIcon,
  WorkOrderRoutingSelect,
} from "../../components";
import { formatDateMmDdYyyy } from "../../helpers/dateHelper";
import { StyledCardStack, StyledHubButton } from "./styles";
import { useParams } from "react-router-dom";
import { useGetWorkOrderData } from "../../hooks";

const LinkProps = { variant: "text", icon: "ExternalLink" };
const Field = ({ field = "", value = "", page = "" }) => (
  <Stack
    gap="5px"
    display="grid"
    gridTemplateColumns="75px 1fr"
    sx={{ a: { lineHeight: 1 } }}
  >
    <Typography variant="caption" sx={{ fontWeight: "600" }}>
      {field}
    </Typography>
    {page ? (
      <StyledHubButton label={value} page={page} {...LinkProps} />
    ) : (
      <Typography variant="caption">{value}</Typography>
    )}
  </Stack>
);

const Title = ({ icon = "", title = "" }) => (
  <Stack direction="row" gap="5px" alignItems="center" mb="10px">
    <HubIcon icon={icon} size={12} />
    <Typography variant="boldCaption" textTransform="none" fontWeight={600}>
      {title}
    </Typography>
  </Stack>
);

const WorkOrderMain = ({}) => {
  const { id } = useParams();
  const { data } = useGetWorkOrderData(id);
  const cardProps = { gap: "10px", padding: "15px", flexGrow: "0 !important" };

  return (
    <StyledCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="Activity" title="Station" />
          <WorkOrderRoutingSelect
            name="routeId"
            workOrder={id}
            routeId={data?.currentRoute}
            hideLabel
          />
        </Stack>
      </HubCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="Layout" title="Order" />
          <Field
            field="P.0.#:"
            value={data?.order?.poNumber}
            page={`/orders/${data?.order?.id}`}
          />
          <Field field="W.0.#:" value={data?.workOrder} />
          <Field field="Quantity:" value={data?.quantity} />
          <Field field="Lot Number:" value={data?.lotNumber} />
        </Stack>
      </HubCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="Calendar" title="Schedule" />
          <Field
            field="Start Date:"
            value={formatDateMmDdYyyy(data?.startDate)}
          />
          <Field field="Due Date:" value={formatDateMmDdYyyy(data?.dueDate)} />
          <Field
            field="Order Date:"
            value={formatDateMmDdYyyy(data?.createdAt)}
          />
        </Stack>
      </HubCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="User" title="Customer" />
          <StyledHubButton
            {...LinkProps}
            label={data?.customer?.name}
            page={`/customers/${data?.customer?.id}`}
          />
        </Stack>
        <Stack>
          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            Ship To
          </Typography>
          {data?.address?.split(",")?.map((a) => (
            <Typography variant="caption">{a}</Typography>
          ))}
        </Stack>
      </HubCardStack>
      <HubCardStack {...cardProps}>
        <Stack>
          <Title icon="Settings" title="Part" />
          <Typography variant="caption"></Typography>
          <StyledHubButton
            {...LinkProps}
            label={data?.part?.partName}
            page={`/parts/${data?.part?.id}`}
          />
        </Stack>
      </HubCardStack>
    </StyledCardStack>
  );
};

export default WorkOrderMain;
