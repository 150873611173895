import React from "react";

import { HubDialogWrapper } from "../HubDialogWrapper";
import AdminCreateLaborContent from "./AdminCreateLaborContent";

export const AdminCreateLaborDialog = ({ routeId, onClose = () => {} }) => {
  return (
    <HubDialogWrapper maxWidth="sm" name="admin-create-labor-ticket">
      <AdminCreateLaborContent routeId={routeId} onClose={onClose} />
    </HubDialogWrapper>
  );
};

export default AdminCreateLaborDialog;
