import React from "react";
import { Stack } from "@mui/material";

import { HubHookFormInput } from "../../components";
import { HubActionDialog } from "../HubActionDialog";
import { StyledSpan } from "./style";
import { getDescriptions } from "./helper";

export const ConfirmWorkOrderStation = ({
  isOpen = false,
  isClosing = false,
  onClose = () => {},
  onSubmit = () => {},
  payload = {},
  data = {},
  ...rest
}) => {
  let route;
  let current = data?.routing?.find((r) => r.id === data.currentRoute);
  if (payload?.routeId)
    route = data?.routing?.find((r) => r.id === payload.routeId);

  const stationLabel = <strong>{route?.station?.label}</strong>;
  const DESCRIPTIONS = getDescriptions(stationLabel);

  let description = DESCRIPTIONS.default;

  let isRework = false;
  if (route) {
    if (route.order > current.order) {
      description = DESCRIPTIONS.advance;
    } else {
      isRework = true;
      description = DESCRIPTIONS.rework;
    }
  }

  if (isClosing) description = DESCRIPTIONS.closing;

  const FormCenter = (
    <Stack direction="row" gap="15px" width="100%">
      <HubHookFormInput
        name="rework"
        label="Completed"
        placeholder="Completed"
        rules={{
          max: {
            value: data?.quantity,
            message: "Completed should not exceed work order quantity",
          },
        }}
        disableHelperText={false}
        required
        isNumber
      />
    </Stack>
  );

  const handleSubmit = (formData) => {
    const rework = Number(formData?.rework);
    const newPayload = {
      ...payload,
      ...(rework ? { rework } : {}),
    };
    onSubmit(newPayload);
  };

  return (
    <HubActionDialog
      onClose={onClose}
      open={isOpen}
      title={isClosing ? "Admin Work Order Close" : "Admin Work Order Update!"}
      description={description}
      handleSubmit={handleSubmit}
      submitLabel={isClosing ? "Confirm Close" : "Confirm Update"}
      action="warning"
      {...(isRework ? { children: FormCenter } : {})}
      {...rest}
    />
  );
};

export default ConfirmWorkOrderStation;
