import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  useGetWorkOrderData,
  usePutAdminCloseWorkOrder,
  usePutAdminUpdateWorkOrder,
} from "../../hooks";
import { ConfirmWorkOrderStation } from "../../dialogs";
import { PageContent } from "../../components";
import { WorkOrder } from "../../containers";

import WorkOrderSkeleton from "./WorkOrderSkeleton";
import WorkOrderContent from "./WorkOrderContent";

export const WorkOrderDetails = () => {
  const { id } = useParams();

  const { data, isLoading, refetch } = useGetWorkOrderData(id);
  const { mutate: putAdminUpdateWorkOrder, isSuccess: isUpdateSuccess } =
    usePutAdminUpdateWorkOrder();
  const { mutate: putAdminCloseWorkOrder, isSuccess: isCloseSuccess } =
    usePutAdminCloseWorkOrder();

  const [payload, setPayload] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  const isSuccess = isUpdateSuccess || isCloseSuccess;

  useEffect(() => {
    if (isSuccess) {
      setPayload(null);
      setIsClosing(false);
      window.location.reload();
    }
  }, [isSuccess, refetch]);

  const handleCloseWorkOrder = () => {
    setIsClosing(true);
    setPayload(true);
  };

  const onSubmit = (putPayload) => {
    console.log(putPayload);
    isClosing
      ? putAdminCloseWorkOrder(id)
      : putAdminUpdateWorkOrder(putPayload);
  };
  const onClose = () => setPayload(null);

  const ContentPayload = { id, data, setPayload, handleCloseWorkOrder };
  const ConfirmPayload = { isClosing, onClose, onSubmit, payload, data };

  return (
    <PageContent
      gap="15px"
      isLoading={isLoading}
      skeleton={<WorkOrderSkeleton />}
      disablePadding
      key={id}
    >
      {data && <WorkOrderContent {...ContentPayload} />}
      <WorkOrder id={id} sx={{ position: "absolute", left: "-9999px" }} />
      <ConfirmWorkOrderStation isOpen={!!payload} {...ConfirmPayload} />
    </PageContent>
  );
};

export default WorkOrderDetails;
