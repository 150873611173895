import { WorkOrder } from "../../containers";
import WorkOrderProd from "./WorkOrderProd";

export const tabs = [
  {
    id: 1,
    label: "Routing & Labor",
    icon: "Settings",
    TabElement: WorkOrderProd,
  },
  { id: 2, label: "Work Sheet", icon: "FileText", TabElement: WorkOrder },
];

const getIsoDate = (date) => new Date(date).toISOString();

export const routeMapper = (items) =>
  items?.map(
    ({
      routeId,
      stationId,
      materialId,
      leadTime,
      order,
      plannedStartDate,
      plannedEndDate,
      startDate,
      endDate,
    }) => ({
      ...(routeId && { routeId }),
      ...(stationId && { stationId }),
      ...(materialId && { materialId }),
      ...(leadTime && { leadTime }),
      ...(order && { order }),
      ...(plannedStartDate && {
        plannedStartDate: getIsoDate(plannedStartDate),
      }),
      ...(plannedEndDate && { plannedEndDate: getIsoDate(plannedEndDate) }),
      ...(startDate && { startDate: getIsoDate(startDate) }),
      ...(endDate && { endDate: getIsoDate(endDate) }),
    })
  );

export const updateMapper = (formData, data, rows, id) => {
  const updatePayload = { id, status: formData?.status };

  const option = data?.routing?.find((r) => r.id === formData.routeId);
  if (option && option.id !== data?.currentRoute) {
    updatePayload.routeId = option.id;
  }

  if (formData?.updatedLabor?.length) {
    updatePayload.updatedLabor = formData.updatedLabor.map((l) => ({
      id: Number(l.laborId),
      employeeId: Number(l.employeeId),
      completed: Number(l.completed),
      scrapped: Number(l.scrapped),
      startDate: getIsoDate(l.startDate),
      endDate: getIsoDate(l.endDate),
      notes: l?.notes,
    }));
  }

  const changed = getChangedValues(rows, formData.routes);
  if (changed.length) updatePayload.updatedRouting = routeMapper(changed);

  if (formData?.deletedRouting?.length)
    updatePayload.deletedRouting = formData.deletedRouting;

  const newRoutes = formData?.routes?.filter((r) => !r?.routeId);
  if (newRoutes.length) updatePayload.addedRouting = routeMapper(newRoutes);

  return updatePayload;
};

export const getChangedValues = (startArray, endArray, key = "routeId") =>
  endArray.reduce((acc, newItem) => {
    const oldItem = startArray.find((item) => item[key] === newItem[key]);
    if (!oldItem) return [...acc, newItem];

    const changes = Object.fromEntries(
      Object.entries(newItem).filter(([k, v]) => v !== oldItem[k])
    );

    return Object.keys(changes).length
      ? [...acc, { [key]: newItem[key], ...changes }]
      : acc;
  }, []);
