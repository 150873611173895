import { TableBody } from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGetLaborDataByRouteId } from "../../hooks";

import LaborTableRow from "./LaborTableRow";

const LaborTableBody = ({ routeId, setDeletingId = () => {} }) => {
  const { data } = useGetLaborDataByRouteId(routeId);
  const { setValue, getValues } = useFormContext();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (data) setFields(data);
  }, [data]);

  const onFieldUpdate = useCallback(
    (prefix) => {
      const item = getValues(prefix);

      if (!item?.laborId) return;

      const updatedLabor = getValues("updatedLabor") || [];
      const updatedIndex = updatedLabor.findIndex(
        (u) => u.laborId === item.laborId
      );

      if (updatedIndex > -1) updatedLabor[updatedIndex] = item;
      else updatedLabor.push(item);

      setValue("updatedLabor", updatedLabor);
    },
    [setValue, getValues]
  );

  return (
    <TableBody>
      {fields?.map((labor) => {
        const prefix = `labor.${labor.laborId}`;
        return (
          <LaborTableRow
            key={labor.laborId}
            prefix={prefix}
            labor={labor}
            onFieldUpdate={onFieldUpdate}
            setDeletingId={setDeletingId}
          />
        );
      })}
    </TableBody>
  );
};

export default React.memo(LaborTableBody);
