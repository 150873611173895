import { Skeleton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  HubCardStack,
  HubHookFormInput,
  HubTabs,
  HubHookFormSelect,
} from "../../components";

export const StyledSelect = styled(HubHookFormSelect)({
  width: 125,
  padding: "5px 10px",
  borderRadius: 6,
  svg: {
    width: 12,
    height: 12,
    margin: "5px 5px 0px 0px",
  },
});

export const StyledCheckboxStack = styled(Stack)({
  display: "grid",
  gridTemplateColumns: "1fr",
});

export const StyledHubHookFormInput = styled(HubHookFormInput)(({ theme }) => ({
  backgroundColor: theme.palette.hubColors.mainFocusLightest,
  borderColor: theme.palette.hubColors.mainFocusLight,
  fieldset: {
    borderColor: theme.palette.hubColors.mainFocusLight,
  },
}));

export const StyledTitleStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "25px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  borderBottom: "1px solid",
  borderColor: theme.palette.hubColors.greyLight,
  backgroundColor: theme.palette.hubColors.white,
}));

export const StyledHubTabs = styled(HubTabs)({
  "&.MuiBox-root": {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    margin: "0 auto",
  },
});

export const StyledHubCardStack = styled(HubCardStack, {
  shouldForwardProp: (prop) => !["disableMx"].includes(prop),
})(({ disableMx }) => ({
  gap: "25px",
  width: "100%",
  minWidth: "700px",
  height: "fit-content",
  // minHeight: "100%",
  ...(disableMx ? {} : { margin: "auto" }),
}));

export const StyledDisplayStack = styled(Stack)({
  gap: "25px",
  padding: "25px",
  width: "100%",
  overflow: "auto",
  scrollbarGutter: "stable",
});

export const StyledCardAndMenuStack = styled(Stack)({
  flexDirection: "row",
  width: "100%",
  height: "calc(100% - 51px);",
  overflow: "hidden",
});

export const StyledDisplayGrid = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "100%",
  gap: "25px",

  display: "grid",
  gridTemplateColumns: "1fr minmax(700px, auto) 1fr",

  [theme.breakpoints.down(1200)]: {
    height: "fit-content",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "start",
    "& .space-div": {
      display: "none",
    },
  },
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  "&:after": {
    background:
      "linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.15), transparent)",
  },
}));
