import React from "react";

import { HubContextMenu } from "../../components";
import { useHubSnackBar } from "../../hooks";
import { useFormContext } from "react-hook-form";
import { handleReschedule } from "./moveHelper";

const MENU_ITEMS = [
  {
    id: "create",
    label: "Create Labor Ticket",
    icon: "User",
  },
  {
    id: "delete",
    label: "Delete",
    icon: "Trash2",
    color: "redError",
  },
];

export const WorkOrderContextMenu = ({
  id,
  remove = () => {},
  setRouteId = () => {},
  disabled,
  routeId,
  ...rest
}) => {
  const { addSnackbarError } = useHubSnackBar();
  const message =
    "This route has been started or completed and cannot be deleted";
  const addMessage = "Please save the route before adding labor tickets";

  const { getValues, setValue } = useFormContext();

  const onSelect = (item) => {
    switch (item?.id) {
      case "delete":
        if (disabled) addSnackbarError({ message });
        else {
          handleRemove();
          remove(id);
          handleReschedule(id, getValues, setValue);
        }
        break;
      case "create":
        if (routeId) setRouteId(routeId);
        else addSnackbarError({ message: addMessage });
        break;
      default:
        break;
    }
  };

  const handleRemove = () => {
    if (routeId) {
      const deleted = getValues("deletedRouting") || [];
      const addedDeleted = [...new Set([...deleted, routeId])];
      setValue("deletedRouting", addedDeleted);
    }
  };

  return (
    <HubContextMenu
      id={"contacts"}
      loading={false}
      menuItems={MENU_ITEMS}
      onSelect={onSelect}
      IconButtonProps={{ sx: { padding: "5px" } }}
      HubIconProps={{ size: 14 }}
      {...rest}
    />
  );
};

export default WorkOrderContextMenu;
